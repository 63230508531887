<template>
    <div class="home">
        <div class="banner">
            <h2>About Us</h2>
        </div>
        <el-main>
            <div class="content">
                <p class="txt">At FindMedicareChoice.com, we understand the importance of managing your budget effectively.


                </p>
                <p class="txt"> That's why we're here to ensure you pay the lowest possible rates for expenses
                    like insurance. With
                    FindMedicareChoice.com, finding the perfect insurance plan is a breeze!
                </p>
                <p class="txt"> It's quick, free, and there's no commitment required. We take away the hassle of contacting
                    multiple
                    insurance agents or spending time filling out online quote forms that may not provide the best rates.
                    Our team conducts thorough research on insurance companies, matches you with providers that meet your
                    needs, and provides you with competitive insurance rate quotes to compare.
                </p>
                <p class="txt"> Choosing affordable and high-quality insurance for yourself or your family is a crucial
                    decision. But we know it can be overwhelming. With FindMedicareChoice.com, we simplify the process for
                    you.
                </p>
                <p class="txt"> Having the peace of mind that you're covered in case of an accident, along with securing a
                    great insurance deal—that's invaluable.
                </p>
                <p class="txt"> FindMedicareChoice.com is your go-to platform to find fantastic rates on your insurance.
                </p>
                <p class="txt"> Why not take the first step?
                </p>
                <p class="txt"> Let us do the work for you. Enter your information to get a free quote instantly.
                </p>
            </div>
        </el-main>
        <slideBar></slideBar>
    </div>
</template>

<script>
import { GetIP, GetIPadd } from '@/api/index'
export default {
    name: 'Result',
    components: {
    },
    data() {
        return {
            MyAdd: ''
        }
    },
    mounted() {
    },
    methods: {
        async getIP() {
            if (!localStorage.getItem('userip')) {
                const res = await GetIP()
                this.getipadd(res.ip)
            } else {
                this.MyAdd = JSON.parse(localStorage.getItem('userip')).city
            }
        },
        async getipadd(ip) {
            const res = await GetIPadd(ip)
            this.MyAdd = res.city
            localStorage.setItem('userip', JSON.stringify(res))
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/layout.scss";

.home {
    margin-top: 70px;

    .banner {
        background-image: url('../assets/imgs/banner3.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100px;
        padding: 100px 0px;
        text-align: center;

        h2 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            line-height: 50px;
            font-size: 56px;
            color: rgba(255, 255, 255, 1);
        }
    }

    .el-main {
        padding-bottom: 200px;
        overflow: hidden;

        .content {
            width: 60%;
            margin: 20px auto;


            .txt {
                font-size: 16px;
                font-weight: 400;
                line-height: 2;
                color: #5D5B5E;
                margin: 15px 0;
            }



        }
    }
}

@media screen and (max-width: 992px) {
    .home {
        .banner {
            h2 {
                font-size: 20px;
            }
        }

        .el-main {
            .content {
                width: 89%;

                .txt {
                    font-size: 20px;
                }

                .cart {
                    width: calc(99% - 20px);
                    float: none;
                    margin: 10px;

                    .row {
                        flex-wrap: wrap;

                        .lf,
                        .rg {
                            flex: 100%;
                        }

                        .lf {
                            .el-button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .home {
        .banner {
            height: 100px;
            padding: 40px 0px;

            h2 {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .el-main {
            .content {
                width: 94%;

                .txt {
                    font-size: 16px;
                }

                .cart {
                    width: calc(99% - 20px);
                    float: none;
                    margin: 10px;

                    .row {
                        flex-wrap: wrap;

                        .lf,
                        .rg {
                            flex: 100%;
                        }

                        .lf {
                            .el-button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
